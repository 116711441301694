@font-face {
  font-family: "Berlin Type";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("/fonts/BerlinType/BerlinTypeWeb-Regular.woff2") format("woff2"),
    url("/fonts/BerlinType/BerlinTypeWeb-Regular.woff") format("woff");
}
@font-face {
  font-family: "Berlin Type";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("/fonts/BerlinType/BerlinTypeWeb-Bold.woff2") format("woff2"),
    url("/fonts/BerlinType/BerlinTypeWeb-Bold.woff") format("woff");
}

:root {
  --vh: 1vh;
}

html,
body {
  padding: 0;
  margin: 0;
  height: stretch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.with-ellipsis {
  display: inline-block;
  overflow: hidden;
  // hyphens: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.clampTwoLines {
  overflow: hidden;
  // hyphens: auto;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.clampThreeLines {
  overflow: hidden;
  // hyphens: auto;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.clampFourLines {
  overflow: hidden;
  // hyphens: auto;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.clampFifeLines {
  overflow: hidden;
  // hyphens: auto;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.maplibregl-popup {
  .maplibregl-popup-tip {
    display: none;
  }

  .maplibregl-popup-content {
    background: transparent;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
}

.popup {
  height: 112px;
  width: 200px;
  border: 1.5px solid;
  border-radius: 10px;
  padding: 6px 8px;
  background: var(--chakra-colors-blur-white);
  backdrop-filter: blur(20px);
  background-blend-mode: lighten;

  font-family: Berlin Type, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.25s;

  &.fadeIn {
    opacity: 1;

    &.fadeOut {
      opacity: 0 !important;
    }
  }

  .title {
    height: 54px;
  }
  .row,
  .row-space-between {
    display: flex;
    justify-content: flex-end;
    height: 37px;
    padding-right: 5px;
    align-items: flex-end;
    flex-direction: row;
    width: 100%;
    &.row-space-between {
      justify-content: space-between;

      .arrow {
        margin-bottom: 5px;
      }
    }
  }

  &.mouse {
    height: 70px;
    .row,
    .row-space-between {
      display: none;
    }
  }

  .arrow {
    display: inline-block;
    overflow: hidden;
    text-indent: 100px;
    font-size: 0;
    white-space: nowrap;
    width: 30px;
    height: 17px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-background-position: center center;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    filter: invert(18%) sepia(82%) saturate(4434%) hue-rotate(355deg)
      brightness(96%) contrast(85%);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDI3IDE0Ij4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoxLjU7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoxLjU7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7fQo8L3N0eWxlPgo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjc1IDEuMDU2KSI+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMC4xLDUuOWgyNS40Ii8+Cgk8bGluZSBjbGFzcz0ic3QxIiB4MT0iMjAuNSIgeTE9Ii0wLjEiIHgyPSIyNS41IiB5Mj0iNS45Ii8+Cgk8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMjAuNSwxMS45bDUtNiIvPgo8L2c+Cjwvc3ZnPgo=);

    &:hover {
      filter: invert(13%) sepia(17%) saturate(7488%) hue-rotate(306deg)
        brightness(99%) contrast(106%);
    }
  }
  .close {
    margin-left: 1px;
    margin-top: 1px;
    overflow: hidden;
    text-indent: 100px;
    font-size: 0;
    white-space: nowrap;
    display: inline-block;
    width: 30px;
    height: 30px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-background-position: center center;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: 175%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDU1IDU1IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoxLjU7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7fQo8L3N0eWxlPgo8ZyBpZD0iWCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS4wNjEgMS4wNjEpIj4KCTxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzNS4yIiB5MT0iMTcuOSIgeDI9IjE3LjUiIHkyPSIzNS42Ii8+Cgk8bGluZSBjbGFzcz0ic3QxIiB4MT0iMzUuMiIgeTE9IjM1LjYiIHgyPSIxNy41IiB5Mj0iMTcuOSIvPgo8L2c+Cjwvc3ZnPgo=);
    filter: invert(18%) sepia(82%) saturate(4434%) hue-rotate(355deg)
      brightness(96%) contrast(85%);
    // &:hover {
    //   filter: invert(13%) sepia(17%) saturate(7488%) hue-rotate(306deg) brightness(99%) contrast(106%);
    // }
  }
}

@media screen and (min-width: 72em) {
  .popup {
    height: 112px;
    width: 200px;
    // font-size: 16px;
    // line-height: 22px;
    // letter-spacing: 1.8px;
  }
}

.aspect {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 66%;
  .ratio {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;

    left: 0;

    div,
    img,
    picture {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.svg {
  filter: invert(18%) sepia(82%) saturate(4434%) hue-rotate(355deg)
    brightness(96%) contrast(85%);
  transition: filter 0.3s;

  &.black {
    filter: none;
  }
}

.svg-dark {
  filter: invert(13%) sepia(17%) saturate(7488%) hue-rotate(306deg)
    brightness(99%) contrast(106%);
}
.imgHover {
  img {
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.5;
    }
  }
}
.svgHover {
  &:hover .svg {
    filter: invert(13%) sepia(17%) saturate(7488%) hue-rotate(306deg)
      brightness(99%) contrast(106%);

    &.black {
      filter: invert(33%);
    }
  }
}

.svgHoverRI {
  filter: invert(18%) sepia(82%) saturate(4434%) hue-rotate(355deg)
    brightness(96%) contrast(85%);
  transition: filter 0.3s;
  &.active {
    filter: none !important;
  }
  &:hover {
    filter: invert(13%) sepia(17%) saturate(7488%) hue-rotate(306deg)
      brightness(99%) contrast(106%);
  }
}

@keyframes loadingAnimationCross {
  0% {
    width: 0%;
    left: 0px;
    right: auto;
  }
  49.99% {
    width: 100%;
    left: 0px;
    right: auto;
  }
  50% {
    width: 100%;
    left: auto;
    right: 0px;
  }
  99.99% {
    width: 0%;
    left: auto;
    right: 0px;
  }
}

.loadingbar {
  display: none;
  width: 0px;
  height: 3px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  background-color: violet;
  &.loading {
    display: block;
    animation: loadingAnimationCross 1.5s infinite;
  }
}

// .mapboxgl-popup-tip,
// <div class="maplibregl-popup mapboxgl-popup maplibregl-popup-anchor-bottom mapboxgl-popup-anchor-bottom" style="max-width: 240px; transform: translate(-50%, -100%) translate(400px, 175px);"><div class="maplibregl-popup-tip mapboxgl-popup-tip"></div><div class="maplibregl-popup-content mapboxgl-popup-content">Construction on the Washington Monument began in 1848.<button class="maplibregl-popup-close-button mapboxgl-popup-close-button" type="button" aria-label="Close popup">×</button></div></div>

.spider-leg-container {
  width: 1px;
  height: 1px;
  overflow: display;
  will-change: transform;

  opacity: 0;
  transition: opacity 0.125s;
  &.fadeIn {
    opacity: 1;

    &.fadeOut {
      opacity: 0 !important;
    }
  }
}

.spider-leg-container:hover {
  cursor: pointer;
}

// .spider-leg-container .spider-leg-pin {
//   position: relative;
//   z-index: 1;
// }

.spider-leg-container .spider-leg-pin.default-spider-pin {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  transform: translateY(-16px) translateX(-16px);
}

// .spider-leg-container .spider-leg-line {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 1px;
//   background: transparent; // linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 25%, #660D36 33%, #660D36);
//   transform-origin: bottom;
//   z-index: 0;
//   height: 0;
// }

// .spider-leg-container:hover .spider-leg-line { background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 28%, #fff 33%, #fff); }

.userlocation .dot {
  border: 2px solid #333;
  background-color: #fff;
  height: 44px;
  width: 44px;
  border-radius: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userlocation .dotdot {
  background-color: #333;
  height: 32px;
  width: 32px;
  border-radius: 34px;
}

.skipToContent {
  position: fixed;
  left: 7px;
  top: 7px;
  z-index: 10003;
  background-color: #fff;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transition: none;
  opacity: 0;
  pointer-events: none;
  padding: 15px;
}

@media screen and (min-width: 72em) {
  .skipToContent {
    left: 17px;
    top: 12px;
  }
}

.tabbed .skipToContent:focus {
  opacity: 1;
  outline-offset: 0px;
}

.tabbed .tabbedFocus:focus {
  outline-color: #e42b20;
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 5px;
}

.tabbedVisible {
  border: orchid 1px solid;
  opacity: 0;
}

.tabbed .tabbedVisible:focus {
  outline-color: #e42b20;
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 5px;
  opacity: 1;
}

.cardContainer {
  scroll-snap-align: start;

  padding-left: 20px;
}

@media screen and (min-width: 45em) {
  .cardContainer {
    padding-left: 0px;
    padding-right: 20px;
  }
}
